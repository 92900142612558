// var baseUrl = 'http://192.168.1.169:25920/clife-boot-api-web/'
const proxy = require('http-proxy-middleware')
// console.log(proxy)

module.exports = function (app) {
    app.use(
        proxy.createProxyMiddleware('/api', {
            // http://localhost:4000/ 地址只是示例，实际地址以项目为准
            ////线上地址
            // target: 'http://1.116.154.35:25920/clife-boot-api-web/',
            ////闻春雨地址
            // target: 'http://192.168.1.105:25920/clife-boot-api-web/',
            // 文杰地址
            target: 'http://localhost:25920/clife-boot-api-web/',
            // 跨域时一般都设置该值 为 true
            changeOrigin: true,
            // 重写接口路由
            pathRewrite: {
                '^/api': '' // 这样处理后，最终得到的接口路径为： http://localhost:8080/xxx
            }
        })
    )
};
// const { createProxyMiddleware } = require("http-proxy-middleware");
// const proxy = require('http-proxy-middleware')
// module.exports = function (app) {
//   app.use(
//     createProxyMiddleware("/api", { // iceApi是自定义的，用的时候也要对应写iceApi
//       target: 'http://192.168.1.169:25920/clife-boot-api-web', // 请求的地址
//       changeOrigin: true,
//       pathRewrite: {"^/api":""}
//     })
//   );

// }
