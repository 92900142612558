import React, { Component, useState } from 'react'
import { withRouter } from 'react-router-dom';
import { Button, Input, Modal, Upload, Select, Radio, message, Table, Tag, } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import UploadImgs from './uploadImgs';
import UploadImg from './uploadImg';
import request from '../../../../../../../../js/http';
import cookie from 'react-cookies';
import './index.css'



let data = {
    hasSpec: 0,
    recomXq: 1,
    goodsProp: '精品',
    goodsProduct: {},
    // selectedRowKeys: [],
    productList: [
        { productDefault: 1 },
        // { productDefault: 0 },
    ],
    goodsParam: [
        {}
    ],
};

class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            previewVisible: false,
            previewImage: '',
            previewTitle: '66666',
            fileList: [],
            goodsClassList: [],
            goodsList: [],
            selectedRowKeys: [],

        }
    }
    componentDidMount() {
        this.props.callback(this.props.location.pathname)
        // console.log(this.props);

        this.getClassId()
        this.getGoodsList()

    }
    getGoodsList() {
        let shopId = cookie.load('shopId'), pageNum = 1, pageSize = 999;
        request('post', "/mall/goodsGoods/queryStoreList", {
            shopId, pageNum, pageSize
        }).then(res => {
          //console.log('仓库辣椒商品列表=>', res);
            if (res.code === 200) {
                let key = 0
                let goodsList = res.data.list.map(item => {
                    item.key = key
                    key++
                    return item
                })
                this.setState({ goodsList })
            }
        })
    }
    getImg(value) {
        // console.log(value, '收到了');
        data.goodsImg = value
    }
    getImgs(value) {
        // console.log(value, '收到了');
        data.detailImgList = value
    }
    getClassId() {
        request('post', '/mall/goodsClass/getAllClass', {
            shopId: cookie.load('shopId'),
            pageNum: "1",
            pageSize: '99'
        }).then(res => {
          //console.log('根据大分类获取小分类=>', res);
            if (res.code === 200) {
                this.setState({ goodsClassList: res.rows })
            }
        })
    }
    nameInput(e) {
        data.goodsName = e.target.value
    }

    titleInput(e) {
        data.goodsTitle = e.target.value
    }
    setLeast = e => {
        data.productList[0].productWeight = e.target.value
        data.goodsProduct.productWeight = e.target.value
        // console.log(e.target.value);
    }
    // setLeasts = e => {
    //     data.productList[1].productWeight = e.target.value
    // }
    setPrice = (e) => {
        data.productList[0].productPrice = e.target.value
        data.goodsProduct.productPrice = e.target.value
        // data.productList[0].productWeight = 1
    }
    // setPrices = (e) => {
    //     data.productList[1].productPrice = e.target.value
    // }

    setUnit = (e) => {
        data.goodsProduct.productUnit = e.target.value
        data.productList[0].productUnit = e.target.value
    }
    // setUnits = (e) => {
    //     data.productList[1].productUnit = e.target.value
    // }
    setStock = (e) => {
        data.productList[0].productStock = e.target.value
        data.goodsProduct.productStock = e.target.value
    }
    // setStocks = (e) => {
    //     data.productList[1].productStock = e.target.value
    // }

    render() {

        ///////溯源组件
        const SuYuanName = (e) => {
            data.goodsParam[0].paramsName = e.target.value
        }
        const SuYuanValue = (e) => {
            data.goodsParam[0].paramsValue = e.target.value
        }
        // const SuYuan = () => {
        //     return (
        //         <>
        //             <div className="sale-item" >
        //                 <span className="sale-item-title">名称 :</span>
        //                 <input className="sale-item-value" placeholder="填写溯源名称" onBlur={SuYuanName} />
        //                 <span className="sale-item-title ml10">内容 :</span>
        //                 <input className="sale-item-value" placeholder="填写名称内容" onBlur={SuYuanValue} />
        //             </div>
        //             {/* <div className="sale-item" >
        //                 <Button type="primary" ghost onClick={SuYuanSave}>保存</Button>
        //                 <Button className="ml10" type="primary" ghost onClick={SuYuanAdd}>添加</Button>
        //             </div> */}

        //         </>
        //     )
        // }
        //////////////是否默认规则组件
        const CheckOne = () => {
            const setDefult = (e) => {
                // console.log(e.target.value);
                data.productList[0].productDefault = e.target.value
                data.goodsProduct.productDefault = e.target.value
            }
            return (
                <Radio.Group name="radiogroup" defaultValue={data.productList[0].productDefault} onChange={setDefult}>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                </Radio.Group>
            )
        };
        //////////////是否包邮组件
        const FreePast = () => {
            const setDefult = (e) => {
                // console.log(e.target.value);
                data.recomXq = e.target.value
            }
            return (
                <Radio.Group name="radiogroup" defaultValue={data.recomXq} onChange={setDefult}>
                    <Radio value={0}>是</Radio>
                    <Radio value={1}>否</Radio>
                </Radio.Group>
            )
        };
        //////////////单选组件
        // const CheckMore = () => {

        //     const setDefult = (e) => {
        //         // console.log(e.target.value);
        //         data.productList[1].productDefault = e.target.value

        //     }
        //     return (
        //         <Radio.Group name="radiogroup" defaultValue={data.productList[1].productDefault} onChange={setDefult}>
        //             <Radio value={1}>是</Radio>
        //             <Radio value={0}>否</Radio>
        //         </Radio.Group>
        //     )
        // };

        ////商品分类组件///
        const ClassOption = () => {
            const { Option } = Select;
            function onChange(value) {
                // console.log(`selected ${value}`);
                data.classId = value
            }

            // function onBlur() {
            //     console.log('blur');
            // }

            // function onFocus() {
            //     console.log('focus');
            // }

            // function onSearch(val) {
            //     console.log('search:', val);
            // }
            return (
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="请选择一个分类"
                    defaultValue={data.classId}
                    optionFilterProp="children"
                    onChange={onChange}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {this.state.goodsClassList.map(item => {
                        return (<Option value={item.classId} key={item.classId.toString()}>{item.className}</Option>)
                    })}

                </Select>
            )
        }
        ////商品等级组件///
        const Level = () => {
            const { Option } = Select;

            const onChange = (value) => {
                // console.log(`selected ${value}`);
                switch (value) {
                    case '精品':
                        data.goodsProp = value;
                        // console.log(data);
                        break;
                    case '优质':
                        data.goodsProp = value;
                        break;
                    case '通货':
                        data.goodsProp = value;
                        break;
                    case '花皮':
                        data.goodsProp = value;
                        break;

                    default:
                        break;
                }
            }
            return (
                <Select style={{ width: 200 }} onChange={onChange} defaultValue={data.goodsProp}>
                    <Option value={'精品'} >精品</Option>
                    <Option value={'优质'} >优质</Option>
                    <Option value={'通货'} >通货</Option>
                    <Option value={'花皮'} >花皮</Option>
                </Select>
            )
        }
        ///////////////删除商品对话框//////////
        const Apps = () => {
            const [isModalVisible, setIsModalVisible] = useState(false);
            const showModal = () => {
                setIsModalVisible(true);
            };
            const handleOk = () => {
                setIsModalVisible(false);
                let { goodsName, recomXq, goodsTitle, classId, goodsImg, hasSpec, goodsParam, bannerList, detailImgList, productList, goodsProp, goodsProduct } = data;
               
                let shopId = cookie.load('shopId')
                goodsParam = JSON.stringify(goodsParam)
                // detailImgList = JSON.stringify(detailImgList)
                // bannerList = JSON.stringify(bannerList)
                // console.log(goodsName, goodsTitle, classId,recomXq, goodsImg, hasSpec, goodsParam, bannerList, detailImgList, productList, goodsProp);
                // console.log(goodsName, goodsTitle, classId, goodsImg, hasSpec, goodsParam, bannerList, detailImgList, productList, goodsProp);
              //console.log(data);
                if (!goodsName) {
                    message.warning('请添加商标题')
                } else if (!productList[0].productStock) {
                    message.warning('请添加商品库存量')
                } else if (!productList[0].productUnit) {
                    message.warning('请添加商品单位')
                } else if (!productList[0].productWeight) {
                    message.warning('请添加商品净重量')
                } else if (!productList[0].productPrice) {
                    message.warning('请添加商品价格')
                } else if (!goodsTitle) {
                    message.warning('请添加商品介绍')
                } else if (!goodsImg) {
                    message.warning('请添加商品列表图')
                } else if (!bannerList) {
                    message.warning('请添加轮播图')
                } else if (!detailImgList) {
                    message.warning('请添加商品详情图')
                } else if (!classId) {
                    message.warning('请添加商品分类')
                } else if (!goodsProp) {
                    message.warning('请添加商品等级')
                } else {
                    // console.log("goodsName, goodsTitle, classId, goodsImg, hasSpec, recomXq, goodsParam, bannerList, detailImgList, productList, goodsProp, shopId");
                    // console.log(goodsName, goodsTitle, classId, goodsImg, hasSpec, recomXq, goodsParam, bannerList, detailImgList, productList, goodsProp, shopId);
                    request('post', '/mall/goodsGoods/add', {
                        goodsName, goodsTitle, classId, goodsImg, hasSpec, recomXq, goodsParam, bannerList, detailImgList, productList, goodsProduct, goodsProp, shopId
                    }).then(res => {
                      //console.log("添加商品信息=>", res);

                        if (res.code === 200) {
                            message.success('商品添加成功,即将返回商品列表')
                            setTimeout(() => {

                                window.location.replace('/index/cksp');
                            }, 1500);

                        } else {
                            message.worning('操作失败')
                        }
                    })
                }
            };
            const handleCancel = () => {
                setIsModalVisible(false);
            };
            return (
                <>
                    <Button type="primary" className="w100" onClick={showModal}  >提交</Button>
                    <Modal className="modal-confirm"
                        title="提示" cancelText="取消"
                        okText="确认"
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                    >
                        <p className="confirm-text">即将提交商品信息?</p>
                    </Modal>
                </>
            );
        };
        ////////////////////////////照片墙上传组件↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
        function getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        }
        //图片预览函数   ///
        const handlePreview = async file => {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }

            this.setState({
                previewImage: file.url || file.preview,
                previewVisible: true,
                previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
            });
        };
        ///////关闭图片预览对话框状态
        const handleCancel = () => this.setState({ previewVisible: false });
        //////上传文件改变时的状态
        const handleChange = ({ fileList }) => {
            this.setState({ fileList });
            if (fileList.length) {
                if (fileList[fileList.length - 1].status === 'done') {
                    // console.log();
                    let list = []
                    fileList.map(item => {
                        let i = {}
                        i.imgurl = item.response.data
                        list.push(i)
                        // console.log(list);
                        data.bannerList = list;
                        return item
                    })
                }
            }
        }



        /// 组件所需要的变量
        const { previewVisible, previewImage, fileList, previewTitle } = this.state;
        //////添加图片按钮
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>添加图片  </div>
            </div>
        );


        /////选择仓库商品///////////////
        /////对话框///////
        const CheckGoodsId = () => {

            const GoodsTable = () => {
                const toEdit = (value) => {
                  //console.log(value);
                    this.props.history.push({ pathname: '/index/cksp/edit', state: { goodsId: value, from: '/index/ccsp' } })
                }
                /////表格 字段///
                const columns = [
                    // { title: '商品ID', dataIndex: 'goodsId', key: 'goodsId', width: 250 },
                    { title: '商品名称', dataIndex: 'goodsName', key: 'goodsName', },
                    { title: '种类 ', dataIndex: 'className', key: 'className', },
                    { title: '等级', dataIndex: 'goodsProp', key: 'goodsProp', },
                    { title: '单位', dataIndex: 'goodsUnit', key: 'goodsUnit', },
                    { title: '库存量', dataIndex: 'productStock', key: 'productStock', },
                    {
                        title: '状态', key: 'goodsLoading', dataIndex: 'goodsLoading', render: (text, record) => {
                            return (
                                <>
                                    {
                                        record.goodsLoading === 0 ? <Tag color="#f50">待上架</Tag> : <>{
                                            record.goodsLoading === 1 ? <Tag color="#87d068">上架</Tag> : <Tag color="#2db7f5">下架</Tag>}</>
                                    }
                                </>
                            )
                        },
                    },
                    {
                        title: '操作', key: 'goodsId', dataIndex: 'goodsId', render: (text, record) => {
                            return <span className="link pointer" onClick={() => toEdit(text)}>选择</span>
                        },
                    },
                ];
                ///表格数据////
                const data = this.state.goodsList
                return (
                    <>
                        <Table
                            columns={columns}
                            dataSource={data}
                            pagination={{
                                showQuickJumper: { goButton: <Button style={{ marginLeft: "10px" }} type="primary" >GO</Button> },
                                showTotal: total => `共 ${total} 条记录`,
                                defaultCurrent: 1,
                                showSizeChanger: false
                            }}
                        />
                    </>
                )
            }
            const [isModalVisible, setIsModalVisible] = useState(false);
            const showModal = () => {
                setIsModalVisible(true);
            };
            const handleCancel = () => {
                setIsModalVisible(false);
            };
            return (
                <>
                    <Button type="primary" ghost onClick={showModal}>选择仓库商品</Button>
                    <Modal title="选择仓库商品" visible={isModalVisible} footer={false} onCancel={handleCancel} width={800}>
                        <GoodsTable />
                    </Modal>
                </>
            );
        };
        return (
            <div className="main-show order-list">
                <div className="table-header">
                    <h1 className="table-name" style={{minWidth:1100}}>商品添加</h1>
                    <div className="table-operation">
                    </div>
                </div>
                <div className="table-header">
                    {/* <h1 className="table-name">商品添加</h1> */}
                    <div className="table-operation">
                        <CheckGoodsId />
                    </div>
                </div>
                <div className="pd20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">标题 :</span>
                        <Input className="goodsinfo-text" placeholder="请输入商品标题" onBlur={this.nameInput} value={this.state.goodsName} />
                        {/* <span className="goodsinfo-text">666</span> */}
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">商品简介 :</span>
                        <Input className="goodsinfo-text" placeholder="请输入商品副标题" onBlur={this.titleInput} />
                        {/* <span className="goodsinfo-text">666</span> */}
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">商品分类 :</span>
                        <ClassOption className="goodsinfo-text" />
                        {/* <span className="goodsinfo-text">666</span> */}
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">商品等级 :</span>
                        <Level className="goodsinfo-text" />
                        {/* <span className="goodsinfo-text">666</span> */}
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">列表图 :</span>
                        <div className="goodsinfo-imgs-area" >
                            <UploadImg getImg={this.getImg} />

                        </div>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">轮播图组 :</span>
                        <div className="goodsinfo-imgs-area" >
                            <Upload
                                action="http://1.116.154.35:25921/clife-boot-api-app/api/cms/common/upload"
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                            >
                                {fileList.length >= 4 ? null : uploadButton}
                            </Upload>
                            <Modal
                                visible={previewVisible}
                                title={previewTitle}
                                footer={null}
                                onCancel={handleCancel}
                            >
                                <img alt="啊哈哈哈哈" style={{ width: '100%' }} src={previewImage} />
                            </Modal>

                        </div>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">详情图组 :</span>
                        <div className="goodsinfo-imgs-area" >
                            <UploadImgs getImgs={this.getImgs} />
                        </div>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="">是否包邮 :</span>
                        <div className="ml50">
                            <FreePast />
                        </div>
                    </div>
                </div>
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">零售 :</span>
                        <div className="goodsinfo-imgs-area" style={{ flexGrow: 1, alignItems: 'center', justifyContent: "flex-start" }} >
                            <div className="sale-item">
                                <span className="sale-item-title">单位净重 :</span>
                                <input className="sale-item-value" placeholder="请输入单位净重" onBlur={this.setLeast} />
                                {/* <span className="sale-item-value" >1</span> */}
                            </div>
                            <div className="sale-item">
                                <span className="sale-item-title">单价(元) :</span>
                                <input className="sale-item-value" placeholder="设置商品单价" onBlur={this.setPrice} />
                            </div>
                            <div className="sale-item">
                                <span className="sale-item-title">单位(规格) :</span>
                                <input className="sale-item-value" placeholder="例: /包/袋/箱/" onBlur={this.setUnit} />
                            </div>
                            <div className="sale-item">
                                <span className="sale-item-title">库存量 :</span>
                                <input className="sale-item-value" placeholder="填写库存量" onBlur={this.setStock} />
                            </div>
                            <div className="sale-item">
                                <span className="sale-item-title">默认 :</span>
                                <CheckOne />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">批量销售 :</span>
                        <div className="goodsinfo-imgs-area" style={{ flexGrow: 1, alignItems: 'center', justifyContent: "flex-start" }} >
                            <div className="sale-item" >
                                <span className="sale-item-title">单位净重 :</span>
                                <input className="sale-item-value" placeholder="请输入单位净重" onBlur={this.setLeast} />
                            </div>
                            <div className="sale-item">
                                <span className="sale-item-title">单价 :</span>
                                <input className="sale-item-value" placeholder="设置商品单价" onBlur={this.setPrices} />
                            </div>
                            <div className="sale-item">
                                <span className="sale-item-title">单位(规格) :</span>
                                <input className="sale-item-value" placeholder="添加商品规格" onBlur={this.setUnits} />
                            </div>
                            <div className="sale-item">
                                <span className="sale-item-title">库存量 :</span>
                                <input className="sale-item-value" placeholder="填写库存量" onBlur={this.setStocks} />
                            </div>
                            <div className="sale-item">
                                <span className="sale-item-title">默认 :</span>
                                <CheckMore />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="pd10-20">
                    <div className="goodsinfo">
                        <span className="goodsinfo-title">商品溯源 :</span>
                        <div className="goodsinfo-imgs-area" style={{ flexGrow: 1, alignItems: 'center', flexDirection: "column" }}>
                            <div style={{ width: '100%', display: "flex", padding: '10px' }}>
                                <div className="sale-item" >
                                    <span className="sale-item-title">名称 :</span>
                                    <input className="sale-item-value" placeholder="填写溯源名称" onBlur={SuYuanName} />
                                    <span className="sale-item-title ml10">内容 :</span>
                                    <input className="sale-item-value" placeholder="填写名称内容" onBlur={SuYuanValue} />
                                </div>
                                {/* <SuYuan /> */}
                                {/* <div className="sale-item" >
                                    <Button type="primary" ghost onClick={SuYuanSave}>保存</Button>
                                    <Button className="ml10" type="primary" ghost onClick={SuYuanAdd}>添加</Button>
                                </div> */}
                            </div>



                        </div>
                    </div>
                </div>
                <div className="pd20 df-jcc" >
                    <Button className="w100" >取消</Button>
                    <Apps />
                </div>
            </div>
        )
    }
}

export default withRouter(Index)
