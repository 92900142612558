import React from 'react';
import { Upload, Modal,  } from 'antd';
import { PlusOutlined } from '@ant-design/icons';



export default class PicturesWall extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            previewVisible: false,
            previewImage: '',
            previewTitle: '66666',
            fileList: [],
        };
    }

render() {

    ////////////////////////////
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    ///关闭图片预览对话框
    const handleCancel = () => this.setState({ previewVisible: false });
    ////图片预览函数   ///
    const handlePreview = async file => {
      //console.log(file);
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };
    //////上传文件改变时的状态
    const handleChange = ({ fileList }) => {
        this.setState({ fileList });
        if (fileList.length) {
            if (fileList[fileList.length - 1].status === 'done') {
                // console.log();
                let list = []
                fileList.map(item => {
                    let i = {}
                    i.imgurl = item.response.data

                    list.push(i)
                    return item
                })
                this.props.getImgs(list)

            }
        } else {
          //console.log('没有数据', fileList.length);

        }

    }
    // /// 组件所需要的变量
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    ///添加图片按钮
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>添加图片  </div>
        </div>
    );
    return (
        <>
            {/* <ImgListUpload /> */}
            <Upload
                action="http://1.116.154.35:25921/clife-boot-api-app/api/cms/common/upload"
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
            >
                {fileList.length >= 4 ? null : uploadButton}
            </Upload>
            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>

        </>
    );
}
}
