import React, { Component, useState } from 'react'
import { withRouter} from "react-router-dom";

import request from '../../../../../../js/http';
import cookie from 'react-cookies';
import "./index.scss";
import { Tabs, Button, Space, message, Tag, Table, Select, Modal } from 'antd';
// import { DownOutlined } from '@ant-design/icons';
// import { UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

let deliver = {
    shopId: cookie.load('shopId'),
    shippingType: '0',
};

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: {},
            list: [],
            orderSnList: [],
            uploadText: '未选择文件'
        }
    }
    componentDidMount() {
        this.props.callback(this.props.location.pathname)

        this.getData(1, 999)
    }
    getData(page, size) {
        let shopId = cookie.load('shopId'), pageNum = page, pageSize = size;
        if (shopId && pageNum && pageSize) {
            request('post', '/mall/orderOrder/order/deliverList', {
                shopId,
                pageSize,
                pageNum
            }).then(res => {
              //console.log('发货管理-待发货列表=>', res);
                if (res.code === 200) {
                    let orderSnList = res.data.list.map(item => {
                        return item.orderSn
                    })
                    // console.log(orderSnList);
                    this.setState({ data: res.data, list: res.data.list, loading: false, orderSnList })
                }
            })
        }
    }

    newTime(ordTime) {
        var date = new Date(ordTime);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = date.getDate() + ' ';
        let h = date.getHours() + ':';
        let m = date.getMinutes() + ':';
        let s = date.getSeconds();
        return Y + M + D + h + m + s;
    }
    ////当页面的表格生成excel文件 并下载////
    download = (e) => {
        message.loading('加载中...')
        let tab = document.getElementById("tables")
        let html = "<html><head><meta charset='UTF-8'></head><body>" + tab.outerHTML + "</body></html>"
        // 创建一个Blob对象，第一个参数是文件的数据，第二个参数是文件类型属性对象
        var blob = new Blob([html], { type: "application/vnd.ms-excel" });
        // 利用URL的createObjectURL方法为元素a生成blobURL
        let a = e.target
        a.href = URL.createObjectURL(blob);
        // 设置文件名
        a.download = "待发货订单表";
    }
    render() {
        ////// 文件上传组件////↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
        // const MyUpload = () => {
        //     const props = {
        //         name: 'file',
        //         action: 'http://1.116.154.35:25921/clife-boot-api-app/api/cms/common/upload',
        //         headers: {
        //             authorization: 'authorization-text',
        //         },
        //         onChange(info) {
        //             console.log(info);
        //             if (info.file.status !== 'uploading') {
        //                 console.log(info.file, info.fileList);
        //             }
        //             if (info.file.status === 'done') {
        //                 message.success(`${info.file.name} 文件上传成功`);
        //             } else if (info.file.status === 'error') {
        //                 message.error(`${info.file.name} 文件上传失败.`);
        //             }
        //         },
        //     };

        //     return (
        //         <Upload {...props}  >
        //             <Button icon={<UploadOutlined />}>上传数据</Button>
        //         </Upload>
        //     )
        // }
        // const { Option } = Select
        /////仓库商品表格模板///////////////
        /////对话框///////
        const CheckGoodsId = () => {

            const GoodsTable = () => {

                /////表格 字段///
                const columns = [
                    // { title: '商品ID', dataIndex: 'goodsId', key: 'goodsId', width: 250 },
                    { title: '订单编号', dataIndex: 'orderSn', },
                    { title: '物流单号', dataIndex: '', width: 100 },
                    { title: '物流公司', dataIndex: '', width: 100 },
                    { title: '支付时间', dataIndex: 'time', },
                    { title: '会员名 ', dataIndex: 'memberName', },
                    { title: '会员手机号', dataIndex: 'memberPhone', },
                    { title: '订单原价(/元)', dataIndex: 'orderPrice', },
                    { title: '实付金额(/元)', dataIndex: 'payment', },
                    { title: '邮费(/元)', dataIndex: 'orderPost', },

                ];
                ///表格数据////
                const data = this.state.list
                return (
                    <div>
                        <div id="tables">
                            <Table
                            bordered
                                columns={columns}
                                dataSource={data}
                                pagination={{
                                    showQuickJumper: { goButton: <Button style={{ marginLeft: "10px" }} type="primary" >GO</Button> },
                                    showTotal: total => `共 ${total} 条记录`,
                                    defaultCurrent: 1,
                                    showSizeChanger: false
                                }}
                            />
                        </div>
                        <p style={{textAlign:"center"}}>

                            <a style={{ margin: '0 auto' }} href='.' onClick={this.download} >下载</a>
                        </p>
                    </div>
                )
            }
            const [isModalVisible, setIsModalVisible] = useState(false);
            const showModal = () => {
                setIsModalVisible(true);
            };
            const handleCancel = () => {
                setIsModalVisible(false);
            };
            return (
                <>
                    <span className="link pointer" title='点击下载发货模板' onClick={showModal}>下载发货模板</span>
                    <Modal title="发货模板" visible={isModalVisible} footer={false} onCancel={handleCancel} width={1200}>
                        <GoodsTable />
                    </Modal>
                </>
            );
        };



        //表格组件
        const TB = () => {
            /////表格 字段///
            const columns = [
                { title: '订单编号', dataIndex: 'orderSn', },
                { title: '支付时间', dataIndex: 'time', },
                { title: '会员名 ', dataIndex: 'memberName', },
                { title: '会员手机号', dataIndex: 'memberPhone', },
                { title: '订单原价(/元)', dataIndex: 'orderPrice', },
                { title: '实付金额(/元)', dataIndex: 'payment', },
                { title: '邮费(/元)', dataIndex: 'orderPost', },
                {
                    title: '状态', dataIndex: 'status', render: (text, record) => {
                        switch (text) {
                            case 2:
                                return (<Tag color="cyan">待发货</Tag>)

                            default:
                                break;
                        }
                        // return (
                        //     <>
                        //     <Tag color="cyan">待发货</Tag>
                        //         {
                        //             // record.status === 0 ? <Tag color="#f50">审核</Tag> : <>{
                        //             //     record.status === 1 ? <Tag color="#87d068">上架</Tag> : <Tag color="#2db7f5">下架</Tag>}</>
                        //         }
                        //     </>
                        // )
                    },
                },
                {
                    title: '操作', dataIndex: 'orderId', render: (text, record) => (
                        <Space size="middle">
                            {/* <Button onClick={(e) => (this.passIdToView(record.goodsId))} ><Link to={{ pathname: "/index/ptsp/detail", state: { goodsList: this.state.goodsList, goodsId: record.goodsId } }}>查看</Link></Button> */}
                            <Link to={{ pathname: "/index/ddlb/detail", state: { orderId: text, from: '/index/fh' } }}>查看</Link>
                            <Link to={{ pathname: "/index/ddlb/detail", state: { orderId: text, from: '/index/fh' } }}>发货</Link>
                            {/* <Link to="/index/cksp/edit">发货</Link> */}
                        </Space>
                    ),
                },
            ];
            /////表格   数据//////
            let newList = this.state.list.map(item => {
                if (item) {
                    item.key = item.orderSn
                }
                // item.key = orderSn
                item.time = this.newTime(item.payAt);
                return item
            })
            // console.log(newList);
            const data = newList


            /////表格勾选
            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                onChange: this.onSelectChange,
            };

            return (
                <div className="main-show order-list">
                    <div className="table-header">
                        <h1 className="table-name">发货记录</h1>
                    </div>
                    <div className="table-content">
                        <Table columns={columns}
                            dataSource={data}
                            loading={this.state.loading}
                            pagination={{
                                showQuickJumper: { goButton: <Button style={{ marginLeft: "10px" }} type="primary" >GO</Button> },
                                total: data.length,
                                showTotal: total => `共 ${total} 条记录`,
                                defaultCurrent: 1,
                                showSizeChanger: false
                            }}
                            rowSelection={rowSelection}
                        />
                        <div className="table-foot">
                            <div className="foot-left">
                                {/* <Button className="table-import" type="text">删除</Button> */}
                                {/* <Button className="table-export" ghost type="primary">导出</Button> */}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        const { TabPane } = Tabs;

        const { Option } = Select
        const Demo = () => {
            ////订单号搜索选择择组件///
            const OrderSnInput = () => {
                const { Option } = Select;
                const onChange = (value) => {
                    // console.log(`selected ${value}`);
                    let order = this.state.list.filter(item => {
                        return item.orderSn === value
                    })
                    let orderId = order[0].orderId
                    deliver.orderId = orderId
                }

                // function onBlur() {
                //     console.log('blur');
                // }

                // function onFocus() {
                //     console.log('focus');
                // }

                // function onSearch(val) {
                //     console.log('search:', val);
                // }
                return (
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="请输入订单号"
                        optionFilterProp="children"
                        onChange={onChange}
                        // onFocus={onFocus}
                        // onBlur={onBlur}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {this.state.orderSnList.map(item => {
                            return <Option value={item} key={item}>{item}</Option>
                        })}
                    </Select>
                )
            }
            /////录入物流号 ///////
            const postCode = e => {
                let value = e.target.value
                if (/^\d{1,19}$/.test(value)) {
                    deliver.shippingCode = value;
                } else {
                    if (/\s/.test(value)) {

                        message.warning("物流号格式错误")
                    } else if (Number(value) !== Number) {
                        // message.warning("")
                    }
                }
            }
            ///////快递公司录入
            const postSelect = (value) => {
                deliver.shippingName = value
            }
            ///////////////确认发货对话框//////////
            const App = () => {
                const [isModalVisible, setIsModalVisible] = useState(false);
                const showModal = () => {
                    setIsModalVisible(true);
                };
                const handleOk = () => {
                    const { shopId, orderId, shippingType, shippingName, shippingCode } = deliver
                    if (shopId && orderId && shippingType && shippingName && shippingCode) {
                        request('post', '/mall/orderOrder/deliver', {
                            shopId, orderId, shippingType, shippingName, shippingCode
                        }).then(res => {
                          //console.log('店铺订单发货(发货页)=>', res);
                            if (res.code === 200) {
                                message.success('发货成功')
                                this.getData(1, 999)
                            } else {
                                message.warning('发货失败,重新填写正确的发货信息再试')
                            }
                        })
                    } else {
                        message.warning('请填写正确的发货信息')
                    }
                    setIsModalVisible(false);
                };
                const handleCancel = () => {
                    setIsModalVisible(false);
                };
                return (
                    <>
                        <span onClick={showModal}>
                            确认发货
                        </span>
                        <Modal className="modal-confirm" title="提示" cancelText="取消" okText="确认" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                            <p className="confirm-text">立即发货?</p>
                        </Modal>
                    </>
                );
            };

            //////文件上传   //// 
            const upload = e => {
              //console.log(e.target.files);

                // let shopId = cookie.load('shopId')
                let uploadText = e.target.files[0].name
                this.setState({ uploadText })
                let file = e.target.files[0]
                // //创建formdata对象
                var formdata = new FormData();
                formdata.append("file", file);
                // formdata.append("shopId", shopId);
                // let xhr = new XMLHttpRequest();
                // xhr.setRequestHeader("Content-type","application/x-www-form-urlencoded")
                // xhr.onreadystatechange = function () {
                //     // 请求响应完毕
                //     // if (xhr.readyState === 4) {
                //     //     if (xhr.status == 200) {
                //     //         // console.log(xhr.responseText);
                //     //         console.log(xhr.response);
                //     //     } else {
                //     //         console.log(xhr.status);
                //     //     }

                //     // }
                //     console.log(xhr.status);
                //     console.log(xhr.response)
                //     console.log(xhr.responseText);
                // }
                // 创建请求
                // xhr.open('post', '/mall/orderOrder/importExcel');
                // xhr.responseType = 'json';
                // 发送请求
                // xhr.send(formdata)
                request('post', '/mall/orderOrder/importExcel', {
                    formdata,
                    shopId: '123'
                }).then(res => {
                  //console.log('导入excel订单批量发货=>', res);
                })
                // console.log(formdata);
            }
            ////当页面的表格生成excel文件 并下载////
            // const download = (e) => {
            //     let tab = document.getElementById("tables")
            //     let html = "<html><head><meta charset='UTF-8'></head><body>" + tab.outerHTML + "</body></html>"
            //     // 创建一个Blob对象，第一个参数是文件的数据，第二个参数是文件类型属性对象
            //     var blob = new Blob([html], { type: "application/vnd.ms-excel" });
            //     // 利用URL的createObjectURL方法为元素a生成blobURL
            //     let a = e.target
            //     a.href = URL.createObjectURL(blob);
            //     // 设置文件名
            //     a.download = "待发货订单表";
            // }
            return (
                // ////分页组件
                <Tabs className="wuliu-tabs" defaultActiveKey="1" >

                    <TabPane tab="单条导入" key="1">
                        <div className="dantiao">
                            <div className='div-item' >
                                <span className="left-title">订单号</span>
                                <OrderSnInput />
                                {/* <input className="inputs" placeholder="请输入订单号" /> */}
                            </div>
                            <div className='div-item'>
                                <span className="left-title"  >物流单号</span>
                                <input className="inputs" placeholder="请输入物流单号" onBlur={postCode} />
                            </div>
                            <div className='div-item'>
                                <span className="left-title" style={{ lineHeight: 2 }}>快递公司</span>
                                <Select defaultValue={this.state.postSelect} style={{ width: 200 }} placeholder="请选择物流公司" onChange={postSelect}>
                                    <Option value="顺丰速运">顺丰速运</Option>
                                    <Option value="京东快递">京东快递</Option>
                                    <Option value="申通快递">申通快递</Option>
                                    <Option value="圆通速递">圆通速递</Option>
                                    <Option value="韵达快递">韵达快递</Option>
                                    <Option value="中通速递">中通速递</Option>
                                    <Option value="百世汇通">百世汇通</Option>
                                    <Option value="天天快递">天天快递</Option>
                                </Select>
                            </div>
                            <div className='div-item'>
                                <span className="left-title"  > {''}</span>
                                <span style={{ width: 192, height: 40, marginLeft: -8 }} > <Button type='primary'  ><App /></Button></span>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab="批量导入" key="2">
                        <div className="piliang">
                            <div className='div-item'  >
                                {/* <span className="left-title">订单号</span> */}
                                {/* <OrderSnInput /> */}
                                {/* <input className="inputs" placeholder="请输入订单号" /> */}
                                <div style={{ paddingTop: 80, }} className="pointer">
                                    <Button ghost type="primary" style={{ width: 100, height: 40 }} >上传</Button>
                                    <br />
                                    <input type='file' className='pointer inputs' accept='.xlsx, .xls' title="点击上传文件" style={{ width: 100, height: 40, position: 'relative', top: -40, opacity: 0 }} onChange={upload} />
                                    <p className="xiazaimoban" style={{ marginTop: -30 }} title={this.state.uploadText === '未选择文件' ? '您选择的文件名' : '已选择一个文件'}>{this.state.uploadText} </p>
                                </div>
                                {/* <a className="xiazaimoban pointer" onClick={download} title="点击下载当前表格">下载发货模板</a> */}
                                <CheckGoodsId />

                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            )
        };


        return (
            <div className="main-show" style={{ backgroundColor: "rgb(240,242,245)" }}>
                <div className="wuliu ">
                    <div className="wuliuTop">
                        <Demo />
                    </div>
                    <div className="wuliuBottom" id="table">
                        <TB />
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(Index)

